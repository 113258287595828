import type { ShortCompanyIssue } from '~/api/modules/companies/types/companies.types'

export function getIssueOrderUrl(issue?: ShortCompanyIssue | null) {
  if (
    issue?.isAvailable === true || issue?.isAvailable === null
  ) {
    return issue?.jamesOrderFormURL?.trim()
  }
  return null
};
